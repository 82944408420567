<template>
<v-container class="bill-confirm-wrapper">
    <div class="bill-confirm">
        <div class="confirm-header">
            <div class="left"></div>
            <div class="right">
                <p>合计付款<span>{{total}}元</span></p>
                <p>账单金额 <span>{{toFix(info.price)}}元</span></p>
                <p>手续费 <span>{{toFix(commission)}}元</span></p>
            </div>
        </div>
        <v-divider class="line" />
        <ul class="bill-info">
            <li>身份证号<span>{{info.id}}</span></li>
            <li>姓名 <span>{{info.name}}</span></li>
            <li>班级 <span>{{info.class}}</span></li>
            <li>支付方式
                <div>
                    <v-radio-group v-model="payMethod" class="pay-method">
                        <v-radio value="1" class="pay-method-item"
                            v-if="(!onlyPayMethod || onlyPayMethod === '1') &&showThisPay(1)" >
                            <template v-slot:label>
                                <i class="pay-icon ali-pay-icon"></i>支付宝支付
                            </template>
                        </v-radio>
                        <v-radio value="4" class="pay-method-item"
                                 v-if="(!onlyPayMethod || onlyPayMethod === '1')&&showThisPay(3)" >
                          <template v-slot:label>
                            <i class="pay-icon ali-pay-icon"></i>支付宝支付(银联商务)
                          </template>
                        </v-radio>
                        <v-radio value="2" v-if="(!onlyPayMethod || onlyPayMethod === '2') &&showThisPay(2)" >
                            <template v-slot:label>
                                <i class="pay-icon wx-pay-icon"></i>微信支付
                            </template>
                        </v-radio>
                        <v-radio value="3" v-if="(!onlyPayMethod || onlyPayMethod === '2') &&showThisPay(3)" >
                          <template v-slot:label>
                            <i class="pay-icon wx-pay-icon"></i>微信支付(银联商务)
                          </template>
                        </v-radio>
                    </v-radio-group>
                </div>
            </li>
        </ul>
        <p class="price-tip">
            {{rateInfo}}
        </p>
    </div>
    <div class="price-info">
        <span>￥{{total}}</span>
        <v-btn
            class="button-submit"
            rounded
            depressed
            color="primary"
            :disabled="!canPay"
            @click="submit"
        >
            确认付款
        </v-btn>
    </div>
</v-container>
</template>

<script>
import {aliPay, wxPay, getRate, allPayMethod,ylPay} from '@/service/service';
export default {
    name: 'BillConfirm',

    data() {
        return {
            info: {},
            payMethod: '',
            onlyPayMethod: '',
            canPay: false,
            rate: 0,
            rateInfo: '',
            patMethodList: [],
        }
    },

    beforeMount() {
        console.log('BillConfirm beforeMount', window.location.href)
        const query = this.$route.query;
        const organ_id = localStorage.getItem('organ');
        this.info = {
            id: query.id,
            name: query.n,
            class: query.c,
            organ: organ_id,
            price: decodeURIComponent(query.p),
        };
        if (this.$isAli) {
            this.onlyPayMethod = this.payMethod = '1'
        }
        if (this.$isWX) {
            this.onlyPayMethod = this.payMethod = '2'
        }
        this.getAllPayMethod(organ_id);
    },

    computed: {
        commission() {
            const p = +this.info.price
            return Math.round(
                (p * this.rate + p * this.rate * this.rate) * 100
            ) / 100 ;
        },
        total() {
           const total = +this.info.price + this.commission;
           return total.toFixed(2);
        },
    },

    watch: {
        payMethod: 'getRate'
    },

    methods: {
        showThisPay(payId) {
          return this.patMethodList.filter(x=>x.methodType ==payId).length >0;
        },
        getAllPayMethod(orgId) {
          allPayMethod({
            organ_id: orgId
          }).then(data=>{
            console.log('allPayMethod', data);
            this.patMethodList = data.data;
            if (this.patMethodList.length > 0) {
                if(this.patMethodList[0].methodType == 3) {
                    if (this.$isAli) {
                        this.payMethod = '4'
                    }
                    if (this.$isWX) {
                        this.payMethod = '3'
                    }
                }
            }
          })
        },
        getRate() {
            this.$loading.show();
            let payType = this.payMethod - 1 + '';
            if (this.payMethod > 2) {
              payType = 3 + '';
            }
            getRate({
                organ_id: localStorage.getItem('organ'),
                type: payType,
            }).then(res => {
                this.$loading.hide();
                if (!res.data) {
                    this.canPay = false;
                    //this.$toast.error('请求失败，请重试')
                } else {
                    this.canPay = true;
                    this.rate = res.data.tax || 0;
                    this.rateInfo = res.data.taxdetail;
                }
            }).catch(err => {
                this.canPay = false;
                this.$loading.hide();
                this.$toast.error(err.msg || '请求失败，请重试')
            })
        },
        submit() {
            if (!this.payMethod) {
                this.$toast.error('请选择支付方式')
                return;
            }
            this.$loading.show();
            const param = {
                idcard: this.info.id,
                type: '0'
            }
            if (this.payMethod === '1' || this.payMethod === 1) {
                this.aliPay(param)
            } else if (this.payMethod === '3' || this.payMethod === 3 || this.payMethod === '4' || this.payMethod === 4) {
                this.ylPayConfirm()
            } else {
                this.wxPay(param)
            }
        },
        toFix(v) {
            return (+v).toFixed(2);
        },
        aliPay(param) {
            aliPay(param).then(res => {
                this.$loading.hide();
                localStorage.setItem('payUser', this.id)
                const div = document.createElement('div');
                div.innerHTML = res.data;
                document.body.appendChild(div);
                document.forms[0].submit();
            }).catch(err => {
                this.$loading.hide();
                this.$toast.error(err.msg || '请求失败');
            });
        },
        wxPay(param) {
            const openid = localStorage.getItem('openid')
            wxPay({
                idcard: param.idcard,
                openid
            }).then(res => {
                this.$loading.hide();
                this.wxPayConfirm(res.data);
            }).catch(err => {
                this.$loading.hide();
                this.$toast.error(err.msg || '请求失败');
            });
        },
        wxPayConfirm(data) {
            if (!window.WeixinJSBridge) {
                this.$error('请在微信中打开');
                return;
            }
            const _this = this;
            window.WeixinJSBridge.invoke(
                'getBrandWCPayRequest', {
                    "appId": data.appId,     //公众号名称，由商户传入
                    "timeStamp": data.timeStamp,         //时间戳，自1970年以来的秒数
                    "nonceStr": data.nonceStr, //随机串
                    "package": data.package,
                    "signType": data.signType,         //微信签名方式：
                    "paySign": data.paySign //微信签名
                },
                function(res){
                    if(res.err_msg == "get_brand_wcpay_request:ok" ){
                        localStorage.setItem('payUser', _this.id)
                        _this.$router.push({
                            name: 'paySuccess'
                        })
                    }
                }
            );
        },
        ylPayConfirm() {
          let param = {
            orgId: this.info.organ,
            payType: this.payMethod,
            idCard: this.info.id,
            orderType: '2'
          }
          ylPay(param).then(data => {
            console.log('ylPayConfirm', data);
            window.location.href = data.data;
            //this.$loading.hide();
          }).catch(()=>{
            this.$loading.hide();
          })
        }
    }
}
</script>

<style lang="scss" scoped>
.bill-confirm-wrapper {
    position: relative;
    height: 100%;
    padding: 0;
    padding-bottom: 67px;
    .price-info {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fbfbfb;
        padding: 12px;
        border-top: 1px solid #efefef;
        display: flex;
        justify-content: space-between;
        align-items: center;
        & > span {
            font-size: 22px;
            color: red;
            font-weight: bold;
        }
        .button-submit {
            width: 120px;
            margin: 0;
        }
    }
}
.bill-confirm {
    height: 100%;
    overflow-y: auto;
    .confirm-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        padding: 12px 12px 0;
        .left {
            width: 25%;
            height: 80px;
            background: url('../../assets/images/bill.png') no-repeat center;
            background-size: 80%;
        }
        .right {
            width: 75%;
            padding: 5px 12px;
            padding-left: 24px;
            p {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 0;
                font-size: 13px;
                color: #888;
                line-height: 1.3;
                &:first-child {
                    font-size: 15px;
                    color: #333333;
                    margin-bottom: 6px;
                }
            }
        }
    }
    .line {
        margin-top: 16px;
    }
    .bill-info {
        padding: 0 12px !important;
        li {
            font-size: 14px;
            padding: 12px;
            color: #333333;
            border-bottom: 1px solid #efefef;
            display: flex;
            justify-content: space-between;
            &:last-child {
                border-bottom: none;
            }
            span {
                color: #666666;
            }
        }
        .pay-method {
            margin-top: 0;
            padding: 0;
            & ::v-deep .v-input__control {
                .v-label {
                    font-size: 14px;
                    color: #666666 !important;
                }
                .v-messages {
                    display: none;
                }
            }
            .pay-icon {
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-right: 4px;
                background-position: center;
                background-size: 100%;
                background-repeat: no-repeat;
                &.ali-pay-icon {
                    background-image: url('../../assets/images/ali_pay.png');
                }
                &.wx-pay-icon {
                    background-image: url('../../assets/images/wx_pay.png');
                }
            }
            .pay-method-item {
                margin-bottom: 16px;
            }
        }
    }
    .price-tip {
        font-size: 12px;
        color: #FFA726;
        line-height: 1.2;
        padding: 0 12px;
    }
}
</style>
